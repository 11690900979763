<template>
  <article
    v-if="!loading && session"
    class="box session mt-4"
  >
    <SessionCard
      :session="session"
      :teachers="teachers || []"
    >
      <SessionAcceptForm
        :session="session"
        @accept="accept"
      />
    </SessionCard>
    <div
      v-if="session.description"
      class="mt-2"
    >
      <VExpansionPanels>
        <VExpansionPanel>
          <VExpansionPanelHeader>
            {{ $t('descriptions.one') }} {{ $t('courses.genitive_one').toLowerCase() }}
          </VExpansionPanelHeader>
          <VExpansionPanelContent>
            <div
              class="bvi-speech"
              v-html="session.description"
            />
          </VExpansionPanelContent>
        </VExpansionPanel>
      </VExpansionPanels>
    </div>
    <div v-if="session.steps_count">
      <VTabs
        v-model="tab"
        class="mt-8"
        @change="changeTabs"
      >
        <VTab href="#lessons">
          {{ $t('lessons.other') }}
        </VTab>
        <VTab
          v-if="session.documents_count"
          href="#documents"
        >
          {{ $t('documents.other') }}
        </VTab>
        <VTab
          v-if="courseBooks.length"
          href="#courseBooks"
        >
          {{ $t('$app.library_documents') }}
        </VTab>
        <VTab
          v-if="textbooks.length"
          href="#textbooks"
        >
          {{ $t('textbooks.other') }}
        </VTab>
      </VTabs>
      <VDivider />
      <VTabsItems
        v-model="tab"
        class="py-4"
      >
        <VTabItem value="lessons">
          <SessionSteps
            v-model="session.steps"
            :session-i-d="session.id"
          />
        </VTabItem>
        <VTabItem
          v-if="filteredDocuments.length"
          value="documents"
        >
          <UDocuments :documents="filteredDocuments" />
        </VTabItem>
        <VTabItem
          v-if="courseBooks.length"
          value="courseBooks"
        >
          <ULibraryDocuments :course-books="courseBooks" />
        </VTabItem>
        <VTabItem
          v-if="textbooks.length"
          value="textbooks"
        >
          <UTextbooks :textbooks="textbooks" />
        </VTabItem>
      </VTabsItems>
    </div>

    <UPlaceholder v-else>
      {{ $t('$app.no_lessons') }}
    </UPlaceholder>
  </article>

  <VProgressCircular
    v-else
    :size="50"
    color="primary"
    indeterminate
    class="app-loader"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import documentTitle from '@/mixins/documentTitle'
import { getSession, getSessionDocuments, getSessionTextbooks, getCourseBooks } from '@/api/api'
import UPlaceholder from '@components/UPlaceholder.vue'
import SessionSteps from '@components/SessionSteps.vue'
import SessionCard from '@components/SessionCard.vue'
import SessionAcceptForm from '@components/SessionAcceptForm.vue'
import UDocuments from '@components/UDocuments.vue'
import UTextbooks from '@components/UTextbooks'
import ULibraryDocuments from '@components/ULibraryDocuments.vue'
import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'

export default {
  name: 'TheSession',

  components: {
    ULibraryDocuments,
    UPlaceholder,
    SessionSteps,
    SessionCard,
    SessionAcceptForm,
    UDocuments,
    UTextbooks
  },

  mixins: [
    documentTitle
  ],

  data () {
    return {
      loading: true,
      tab: 'lessons',
      session: null,
      teachers: [],
      documents: null,
      courseBooks: [],
      textbooks: [],
    }
  },

  computed: {
    ...mapGetters({
      limits: getters.VENDOR_LIMITS,
    }),
    filteredDocuments () {
      /* Фильтр дубликатов документов */
      return (this.documents || []).reduce((acc, document) => {
        const isCopy = acc.some(doc => doc.id === document.id)
        if (!isCopy) {
          acc.push(document)
        }

        return acc
      }, [])
    }
  },

  watch: {
    $route: {
      handler: async function (to) {
        this.loading = true

        try {
          if (this.documents === null && to.query.tab === 'documents') {
            const { documents } = await getSessionDocuments(to.params.sessionID)
            this.documents = this.prepareDocuments(documents || [])
          }

          if (this.session === null) {
            const { now, session, teachers } = await getSession(to.params.sessionID)
            this.updateNow(now)
            this.session = session || {}
            this.teachers = teachers || session.teachers || []

            if (!session || (!session.is_active && session.order_status.code !== 'training_complete')) {
              throw new Error(`Учебная сессия ${session.id} в данный момент недоступна`)
            }

            if (this.limits.infobase_available) {
              const { nodes } = await getCourseBooks(session.course_id)
              this.courseBooks = nodes || []
            }

            if (session.textbooks_count) {
              const { textbooks } = await getSessionTextbooks(this.session.id, true)
              this.textbooks = textbooks || []
            }

            this.documentTitle = session.course_title
          }

          this.loading = false
        } catch (err) {
          this.createNotification({
            type: 'error',
            msg: err.message
          })

          if (!err.message.includes('в своём профиле')) {
            this.$router.replace({ name: 'sessions' }).catch(() => {})
          }
        }
      },
      immediate: true
    }
  },

  created () {
    this.register()
  },

  methods: {
    ...mapActions({
      createNotification: actions.CREATE_NOTIFICATION,
      updateNow: actions.UPDATE_NOW
    }),

    accept (session) {
      this.session = { ...this.session, ...session }
    },

    changeTabs (val) {
      const query = Object.assign({}, this.$route.query, {
        tab: val
      })

      this.$router.replace({ query }).catch(() => {})
    },

    register () {
      this.tab = this.$route.query.tab || this.tab
    },

    prepareDocuments (documents) {
      return [...documents.reduce((map, document) => {
        if (!map.has(document.uri)) {
          map.set(document.uri, document)
        }

        return map
      }, new Map()).values()]
    }
  }
}
</script>
