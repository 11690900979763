<template>
  <VList v-if="textbooks.length">
    <VListItem
      v-for="textbook in textbooks"
      :key="textbook.id"
      :href="`${textbook.url}`"
      target="_blank"
      rel="noopener noreferrer"
      :title="textbook.title"
    >
      <VListItemAvatar
        class="align-self-start mt-4"
        tile
        :size="24"
      >
        <VIcon>
          $uchi_book
        </VIcon>
      </VListItemAvatar>
      <VListItemContent>
        <VListItemTitle>{{ textbook.title }}</VListItemTitle>
      </VListItemContent>
    </VListItem>
  </VList>
</template>

<script>
export default {
  name: 'UTextbooks',
  props: {
    textbooks: {
      type: Array,
      default: () => []
    }
  },
}
</script>
