<template>
  <div class="library-documents__container">
    <VRow>
      <VCol
        v-for="book in courseBooks"
        :key="book.id"
        xl="3"
        lg="4"
        sm="6"
        xs="12"
      >
        <RouterLink
          style="text-decoration: none; color: inherit;"
          :to="`/library/book/${book.id}`"
        >
          <div class="library-documents">
            <VCard class="library-documents__card">
              <div class="library-documents__img-group">
                <img
                  v-if="book.cover"
                  class="library-documents__img"
                  :src="`/${ book.cover }`"
                  :alt="book.title"
                >
                <img
                  v-else
                  class="library-documents__img-default"
                  src="/assets/images/default-document-cover.svg"
                  :alt="book.title"
                >
              </div>
              <div class="library-documents__items">
                <VCardTitle
                  v-show="book.title"
                  :title="book.title"
                  class="library-documents__card-title"
                >
                  {{ promotedLibraryTitle (book) }}
                </VCardTitle>
                <VCardText
                  v-show="book.author"
                  class="library-documents__card-text-author"
                >
                  <strong>{{ $t('$app.library_author') + ':' }} </strong>{{ promotedLibraryAuthor (book) }}
                </VCardText>
                <VCardText
                  v-show="book.publication_year"
                  class="library-documents__card-text-year"
                >
                  <strong>{{ $t('$app.library_publication_year') + ':' }} </strong>{{ book.publication_year }}
                </VCardText>
              </div>
            </VCard>
          </div>
        </RouterLink>
      </VCol>
    </VRow>
  </div>
</template>

<script>

export default {
  name: 'ULibraryDocuments',
  props: {
    courseBooks: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    promotedLibraryTitle (book) {
      if (book.title.length > 60) {
        return book.title.slice(0, 60) + '...'
      } else {
        return book.title
      }
    },

    promotedLibraryAuthor (book) {
      if (book.author.length > 20) {
        return book.author.slice(0, 20) + '...'
      } else {
        return book.author
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.library-documents {
  height: 100%;
}

.library-documents__container {
  width: 100%;
  padding: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

.library-documents__container .col {
  flex-basis: auto;
}

.library-documents__title {
  margin-bottom: 1rem;
}

.library-documents__img-group {
  display: contents;
}

.library-documents__img,
.library-documents__img-default {
  width: 50%;
  padding: 1rem;
  object-fit: contain;
}

.library-documents__items {
  display: flex;
  justify-content: space-between;
  width: 50%;
  flex-direction: column;
}

.library-documents__card {
  display: flex;
  height: 100%;
  transition: linear 0.1s;
}

.library-documents__card:hover {
  opacity: 0.8;
}

.library-documents__card-title {
  display: block;
}
</style>
